<template>
  <SchoolAwayEntitlementTable
    :items="items"
    :is-busy="isBusyStore"
    :pagination="pagination"
    @navigateToIndividual="goToTeacherAssignment"
  ></SchoolAwayEntitlementTable>
</template>

<script>
import { mapGetters } from "vuex";
import SchoolAwayEntitlementTable from "@/modules/school/components/management/away/entitlement/SchoolAwayEntitlementTable";

export default {
  name: "TeacherAwayAssignment",
  components: { SchoolAwayEntitlementTable },
  data() {
    return {
      isBusy: false,
    };
  },
  mounted() {
    this.fetch();
  },
  beforeDestroy() {
    this.$store.commit("setTeacherList", []);
  },
  methods: {
    fetch() {
      this.isBusy = true;
      this.$store
        .dispatch("fetchTeacherList")
        .finally(() => (this.isBusy = false));
    },

    /**
     * Navigate to Teacher individual entitlement list
     *
     * @param data
     */
    goToTeacherAssignment(data) {
      console.log(data);
      this.$router.push({
        name: "teacherAwayAssignment",
        query: { id: data.item.id, name: data.item.name },
      });
    },
  },
  computed: {
    ...mapGetters({
      items: "getTeacherList",
      pagination: "getTeacherListPagination",
      isBusyStore: "getTeacherListBusy",
    }),
  },
};
</script>

<style scoped></style>
